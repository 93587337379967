.cactus-text {
    display: inline-block;
    font-size: x-large;
    text-align: center;
    width: 100%;
}

.cactus-of-things-site-link {
    color: white;
    text-align: center;
    width: 100%;
    display: inline-block;
    font-style: italic;
    text-decoration: none;
}

.shadow-box-cactus {
    background-color: #0c703e;
    color: white;
}

.shadow-box-cactus-light {
    background-color: #C8E5A8;
    color: rgb(51, 51, 51);
}

.cactus-image-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cactus-body {
    background-image: url('../../../../public/images/cactus-logo.svg');
    background-attachment: fixed;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}