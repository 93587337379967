.padding {
    height: 4rem;
}

.padding-landing-screen {
    background-color: #002847ff;
}

.shadow-box-container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    justify-content: center;
}

.shadow-box {
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
    /* display: inline-block; */
    width: fit-content;
    height: fit-content;
    padding: 0.5rem;
    margin: 0.5rem;
    max-width: max(35vw, min(25rem, calc(100vw - 5rem)));
    font-weight: lighter;
}

.shadow-box-light {
    background-color: white;
    color: rgb(51, 51, 51);
}

.shadow-box-dark {
    background-color: rgb(51, 51, 51);
    color: white;
}

.section-title {
    display: block;
    margin: 0.5em;
    font-size: xx-large;
    font-weight: lighter;
    text-align: center;
}

.hover-box {
    transition: all 0.3s ease-in-out;    
}

.hover-box:hover {
    scale: 105%;
    cursor: pointer;
}

.shadow-box-dark-clear {
    background-image: linear-gradient(127deg, rgba(51, 51, 51, 0.8), rgba(51, 51, 51, 0.9));
    color: white;
    border: 1px solid rgb(113, 113, 113);
    backdrop-filter: blur(0.4vmin);
    background-color: transparent;
}

.shadow-box-light-clear {
    background-image: linear-gradient(127deg, rgba(215, 215, 215, 0.8), rgba(215, 215, 215, 0.9));
    color: black;
    border: 1px solid rgb(235, 235, 235);
    backdrop-filter: blur(0.4vmin);
    background-color: transparent;
}

.app-main-content {
    min-height: calc(100vh - 8rem);
}
