.nav-bar {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    /* border-bottom: 1px solid white; */
    /* background-image: linear-gradient(#111111, #444444); */
    background-color: rgb(51, 51, 51);
    transition: 0.25s all ease-in-out;
    box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.2);
}

.nav-div {
    vertical-align: middle;
    display: flex;
    padding: 0.7rem;
    height: calc(100% - 1.4rem);
    width: calc(100% - 1.4rem);
    display: flex;
    flex-direction: row;
}

.nav-div > * {
    align-self: center;
    transition: all 0.25s ease-in-out;
}

.nav-div > a {
    font-size: large;
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-weight: lighter;
    flex: none;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.nav-div > a:not(.logo) {
    margin: 10px;
}

.collapsed {
    background-color: #00000000;
    background-image: none;
    border: none;
    box-shadow: none;
}

.logo {
    height: 100%;
    width: auto;
}

.logo > img {
    transition: 0.25s all ease-in-out;
    animation: shrink-logo reverse 0.3s;
    /* translate: 0 0.25rem; */
    height: 100%;
    width: auto;
}

.logo-collapsed {
    width: 0;
    flex: none !important;
}

.logo-collapsed > img {
    animation: shrink-logo forwards 0.3s;
    width: 0;
}

.collapsed a {
    scale: 120%;
    flex: 1;
}

.current-item {
    scale: 120%;
}

@keyframes shrink-logo {
    0% {
        width: auto;
        opacity: 100%;
        scale: 100%;
    }

    100% {
        width: 0;
        opacity: 0%;
        scale: 0%;
    }
}