.projects-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 4rem;
    padding-top: calc(4rem - 4em);
    justify-content: center;
}

.projects-container > * {
    animation: slide-in forwards 0.3s ease-in-out;
}

.selected-project {
    animation: grow forwards 0.2s ease-in-out;
}

.project-thumbnail-root {
    /* border: 1px solid black; */
    border-radius: 1rem;
    padding: 1rem;
    transition: all 0.25s ease-in-out;
    margin: 1rem;
    width: 20rem;
    background-color: white;
    box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.2);
}

@media (hover: hover) {
    .project-thumbnail-root:hover {
        scale: 105%;
        cursor: pointer;
    }   
}

.thumbnail-image {
    width: 10rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.thumbnail-title {
    text-align: center;
    font-weight: lighter;
    font-size: x-large;
    color: black;
}

.thumbnail-subheading {
    color: black;
    font-weight: lighter;
    font-style: italic;
    text-align: center;
    margin-bottom: 1rem;
}

.thumbnail-content {
    color: black;
    font-weight: lighter;
    margin-bottom: 1rem;
}

.thumbnail-tags {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.thumbnail-tags > img {
    margin: 0.5rem;
    border-radius: 0.5em;
}

.projects-switching > *{
    animation: slide-out forwards 0.3s ease-in-out;
}


@keyframes slide-out {
    0% {
        translate: 0 0;
    }

    100% {
        translate: calc(-4rem - 100vw) 0;
    }
}

@keyframes slide-in {
    0% {
        translate: calc(4rem + 100vw) 0;
    }

    100% {
        translate: 0 0;
    }
}

@keyframes slide-down {
    0% {
        translate: 0 calc(-4rem - 100vh);
    }

    100% {
        translate: 0 0;
    }
}

@keyframes grow {
    0% {
        scale: 100%;
    }

    100% {
        scale: 2000%
    }
}

.project-types {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    height: 3em;
    justify-content: center;
    position: sticky;
    top: 4.5rem;
    z-index: 1000;
    background-color: rgb(51, 51, 51);
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
    animation: slide-down forwards 0.3s ease-in-out;}

.project-types > * {
    font-size: normal;
    font-weight: lighter;
    margin: 0.5rem;
    color: rgb(200, 200, 200);
    align-self: center;
    display: block;
    transition: all 0.25s ease-in-out;
}

@media (hover: hover) {
    .project-types > *:hover {
        font-size: x-large;
    }
}

.selected-type {
    /* text-decoration: underline; */
    color: white;
    /* text-underline-offset: 0.2em; */
}

.selected-type::after {
    opacity: 100% !important;
}

.project-types > *::after {
    display: block;
    content: '';
    margin-left: calc(50% - 0.3em / 2);
    width: 0.3em;
    height: 0.3em;
    background: white;
    border-radius: 1rem;
    opacity: 0%;
    transition: all 0.25s ease-in-out;
}
