.key-technologies-container {
    margin-top: 1rem;
}

.rotogran-text {
    display: inline-block;
    font-size: x-large;
    text-align: center;
    width: 100%;
}

@media (max-width: 1000px) {
    .key-technologies-container {
        border-radius: 0.5rem;
        box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
        padding: 0.5rem;
        background-color: white;
    }

    .contents {
        margin-top: 1.5rem;
        border-radius: 0.5rem;
        box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
        padding: 0.5rem;
        background-color: white;
        margin-bottom: 1.5rem;
    }
}

@media (min-width: 1001px) {
    .rotogran-text {
        padding-right: 1rem;
    }
}

.rotogran-site-link {
    color: white;
    text-align: center;
    width: 100%;
    display: inline-block;
    font-style: italic;
    text-decoration: none;
}

.shadow-box-app-home-screen {
    background-color: #d20021;
    color: white;
}

.lock-image {
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: auto;
    margin-top: 1rem;
    border-radius: 0.5rem;
}

.shadow-box-app-inventory {
    background-color: #fafafa;
    color: rgb(51, 51, 51);
}

.see-also {
    text-align: center;
}
