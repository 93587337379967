.landing-screen {
    padding-top: 5rem;
    height: calc(100vh - 4rem);
    background-color: #002847ff;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.landing-logo {
    width: 50%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    background-size: 100% 140%;
    background-repeat: no-repeat;
    background-position: center;
}

.cursor-text-container {
    text-align: center;
    padding: 0.25rem;
    border: 1px solid white;
    display: block;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 2vmin;
}

.cursor-text {
    color: white;
    font-size: 5vmin;
    font-weight: lighter;
    display: inline-block;
    font-family: monospace;
}

.cursor-text::after {
    content: "";
    margin-left: 0.5vmin;
    width: 1vmin;
    height: 5vmin;
    translate: 0 0.5vmin;
    background: white;
    display: inline-block;
    animation: blink forwards 1s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 100%;
    }

    25% {
        opacity: 100%;
    }

    75% {
        opacity: 0%;
    }

    100% {
        opacity: 0%;
    }
}

.socials-container {
    text-align: center;
    padding: 2rem;
}

.socials-container > * {
    margin: 1rem;
}

@media (hover: hover) {
    .socials-container img:hover {
        scale: 110%;
    }
}

.socials-container img {
    height: 5rem;
    width: auto;
    transition: all 0.25s ease-in-out;
}

.featured-projects {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: #f5f5f7;
    padding-bottom: 1rem;
}

.featured-projects-title {
    text-align: center;
    padding: 1rem;
}

.featured-projects-title span {
    font-size: xx-large;
    font-weight: lighter;
}

.featured-projects-background-wrapper {
    background-color: rgb(51, 51, 51);
}
