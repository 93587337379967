.parent-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    vertical-align: middle;
    flex-wrap: wrap;
    align-items: center;
    width: fit-content;
}

.responsive-image {
    height: auto;
}

.responsive-caption {
    font-size: medium;
    font-weight: lighter;
    width: fit-content;
    vertical-align: middle;
}

@media (max-width: 1000px) { /* mobile */
        .responsive-caption {
            text-align: center;
        }

        .responsive-image {
            max-width: calc(100% - 2rem);
        }
}

@media (min-width: 1001px) { /* laptop */
    .responsive-caption {
        text-align: left;
        height: max-content;
        max-width: 60%;
        overflow-wrap: break-word;
    }

    .responsive-image {
        width: auto;
        height: auto;
        display: inline-block;
        max-width: 30%;
        margin: 1rem;
    }
}