.footer {
    background-color: rgb(51, 51, 51);
    color: white;
    font-weight: lighter;
    height: 2rem;
    padding: 1rem;
    display: flex;
    justify-content: right;
    align-items: center;
    z-index: 10000;
}
